<template>
  <CreateUpdateTemplate
    :routePreventDialog.sync="routePreventDialog"
    customClass="user-create create-form"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Create a new User</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="onSubmit"
        color="cyan"
      >
        Save User
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="userForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="onSubmit"
      >
        <v-container fluid>
          <v-row>
            <v-col md="10" offset-md="1" class="pb-0">
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll"
                style="position: static"
              >
                <v-row>
                  <v-col
                    class="iv-custom-field"
                    cols="3"
                    md="3"
                    sm="3"
                    v-on:mouseleave="imageTemplate = false"
                    v-on:mouseover="imageTemplate = true"
                  >
                    <template>
                      <v-img
                        contain
                        :lazy-src="$defaultProfileImage"
                        :src="getProfileImage"
                        max-height="200"
                        max-width="200"
                        height="200px"
                        width="200px"
                        class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
                      >
                      </v-img>
                      <div class="margin-auto">
                        <v-btn
                          class="my-4 custom-bold-button width-100"
                          outlined
                          small
                          v-on:click="selectImage"
                          color="cyan"
                          :loading="imageLoading"
                          :disabled="imageLoading"
                        >
                          <template v-if="getProfileImage"
                            >Change Image</template
                          >
                          <template v-else>Upload Image</template>
                        </v-btn>
                        <div class="d-none">
                          <v-file-input
                            ref="uploadImage"
                            v-on:change="updateProfileImage"
                            :rules="imageRules"
                            accept="image/png, image/jpeg, image/jpg"
                          ></v-file-input>
                        </div>
                      </div>
                    </template>
                  </v-col>
                  <v-col md="9" class="py-0 pt-4">
                    <v-row>
                      <v-col v-if="false" md="4" class="py-0">
                        <label class="font-weight-600 font-size-16 required"
                          >Salutation</label
                        >
                        <v-select
                          :items="salutationList"
                          dense
                          filled
                          item-text="text"
                          item-value="value"
                          item-color="cyan"
                          label="Salutation"
                          solo
                          flat
                          color="cyan"
                          class="required-field"
                          v-model.trim="updateUser.title"
                          :rules="[
                            validateRules.required(
                              updateUser.title,
                              'Salutation'
                            ),
                          ]"
                        ></v-select>
                      </v-col>
                      <v-col md="4" class="py-0">
                        <label class="font-weight-600 font-size-16 required"
                          >First Name</label
                        >
                        <v-text-field
                          dense
                          filled
                          label="First Name"
                          solo
                          flat
                          color="cyan"
                          v-model.trim="updateUser.first_name"
                          :rules="[
                            validateRules.required(
                              updateUser.first_name,
                              'First Name'
                            ),
                            validateRules.minLength(
                              updateUser.first_name,
                              'First Name',
                              2
                            ),
                            validateRules.maxLength(
                              updateUser.first_name,
                              'First Name',
                              100
                            ),
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col md="4" class="py-0">
                        <label class="font-weight-600 font-size-16 required"
                          >Last Name</label
                        >
                        <v-text-field
                          dense
                          filled
                          label="Last Name"
                          solo
                          flat
                          color="cyan"
                          v-model.trim="updateUser.last_name"
                          :rules="[
                            validateRules.required(
                              updateUser.last_name,
                              'Last Name'
                            ),
                            validateRules.minLength(
                              updateUser.last_name,
                              'Last Name',
                              2
                            ),
                            validateRules.maxLength(
                              updateUser.last_name,
                              'Last Name',
                              100
                            ),
                          ]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="4" class="py-0">
                        <label class="font-weight-600 font-size-16 required"
                          >User Name</label
                        >
                        <v-text-field
                          dense
                          filled
                          label="User Name"
                          solo
                          flat
                          color="cyan"
                          v-model.trim="updateUser.user_name"
                          :rules="[
                            validateRules.required(
                              updateUser.user_name,
                              'User Name'
                            ),
                            validateRules.minLength(
                              updateUser.user_name,
                              'User Name',
                              2
                            ),
                            validateRules.maxLength(
                              updateUser.user_name,
                              'User Name',
                              100
                            ),
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col md="4" class="py-0">
                        <label class="font-weight-600 font-size-16 required"
                          >User Email</label
                        >
                        <v-text-field
                          dense
                          filled
                          label="User Email"
                          solo
                          flat
                          color="cyan"
                          v-model.trim="updateUser.user_email"
                          :rules="[
                            validateRules.validEmail(
                              updateUser.user_email,
                              'User Email'
                            ),
                            validateRules.required(
                              updateUser.user_email,
                              'User Email'
                            ),
                            validateRules.minLength(
                              updateUser.user_email,
                              'User Email',
                              2
                            ),
                            validateRules.maxLength(
                              updateUser.user_email,
                              'User Email',
                              100
                            ),
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col md="4" class="py-0">
                        <label class="font-weight-600 font-size-16 required"
                          >Phone Number</label
                        >
                        <PhoneTextField
                          id="t-primary_phone"
                          v-model="updateUser.phone_number"
                          :value="updateUser.phone_number"
                          required
                        >
                        </PhoneTextField>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="4" class="py-0">
                        <label class="font-weight-600 font-size-16 required"
                          >Role</label
                        >
                        <v-select
                          :items="roles"
                          dense
                          filled
                          item-text="name"
                          item-value="id"
                          item-color="cyan"
                          label="Role"
                          solo
                          flat
                          v-model.trim="updateUser.role"
                          color="cyan"
                          hide-details
                          :rules="[
                            validateRules.required(updateUser.role, 'Role'),
                          ]"
                        ></v-select>
                        <em class="font-size-10" v-if="updateUser.role == 1"
                          >Unrestricted access to all modules.</em
                        >
                        <em class="font-size-10" v-if="updateUser.role == 2"
                          >Sales executive.</em
                        >
                      </v-col>
                      <v-col md="4" class="py-0">
                        <label class="font-weight-600 font-size-16 required"
                          >Display Name</label
                        >
                        <v-text-field
                          dense
                          filled
                          label="Display Name"
                          solo
                          flat
                          color="cyan"
                          v-model.trim="updateUser.display_name"
                          :rules="[
                            validateRules.required(
                              updateUser.display_name,
                              'Display Name'
                            ),
                            validateRules.minLength(
                              updateUser.display_name,
                              'Display Name',
                              2
                            ),
                            validateRules.maxLength(
                              updateUser.display_name,
                              'Display Name',
                              100
                            ),
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col md="4" class="py-0">
                        <label class="font-weight-600 font-size-16 required"
                          >Designation</label
                        >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          :items="designationList"
                          v-model.trim="updateUser.designation"
                          label="Designation"
                          solo
                          flat
                          append-outer-icon="mdi-cog"
                          v-on:click:append-outer="
                            manageDesignationDialog = true
                          "
                          item-color="cyan"
                          hide-details
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Designation Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col v-if="false" md="12" class="py-0">
                        <label class="font-weight-600 font-size-16"
                          >Commission Duration</label
                        >
                        <v-layout>
                          <v-flex md5>
                            <Datepicker
                              v-model="updateUser.commission_start"
                              :max-date="updateUser.commission_end"
                              show-details
                              solo
                              nullable
                              placeholder="Start Date"
                            ></Datepicker>
                          </v-flex>
                          <v-flex md5>
                            <Datepicker
                              v-model="updateUser.commission_end"
                              :min-date="updateUser.commission_start"
                              show-details
                              solo
                              nullable
                              placeholder="End Date"
                            ></Datepicker>
                          </v-flex>
                        </v-layout>
                      </v-col>
                      <v-col v-if="false" md="10" class="py-0">
                        <label class="font-weight-600 font-size-16"
                          >Commission</label
                        >
                        <v-layout>
                          <v-flex md4>
                            <v-text-field
                              dense
                              filled
                              solo
                              flat
                              suffix="% of "
                              v-mask="'###'"
                              color="cyan"
                              v-model.trim="updateUser.commission"
                            >
                            </v-text-field>
                          </v-flex>
                          <v-flex md8>
                            <v-select
                              :items="commissionType"
                              dense
                              filled
                              solo
                              flat
                              hide-details
                              v-model.trim="updateUser.commission_type"
                              color="cyan"
                              item-color="cyan"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </perfect-scrollbar>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <ManageDesignation
        :common-dialog="manageDesignationDialog"
        :designations="designationList"
        v-on:close-dialog="manageDesignationDialog = false"
        v-on:get-designation="getOptions()"
      ></ManageDesignation>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { POST, GET } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import Datepicker from "@/view/pages/partials/Datepicker";
import ManageDesignation from "@/view/pages/partials/Manage-Designation.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin, FileManagerMixin],
  name: "user-create",
  data() {
    return {
      roles: [],
      manageDesignationDialog: false,
      designationList: [],
      updateUser: {
        title: null,
        first_name: null,
        last_name: null,
        user_name: null,
        user_email: null,
        phone_number: null,
        role: null,
        display_name: null,
        commission_start: null,
        commission_end: null,
        commission: 0,
        commission_type: 1,
        profile_logo: null,
      },
      commissionType: [
        {
          text: "Quotation Amount",
          value: 1,
        },
        {
          text: "Quotation Profit",
          value: 2,
        },
        {
          text: "Invoice Amount",
          value: 3,
        },
        {
          text: "Invoice Paid Amount",
          value: 4,
        },
      ],
      imageTemplate: false,
      imageLoading: false,
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  components: {
    Datepicker,
    PhoneTextField,
    ManageDesignation,
    CreateUpdateTemplate,
  },
  methods: {
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "user/options" })
        .then(({ data }) => {
          _this.designationList = data.designation;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          _this.updateUser.profile_logo = _this.lodash.head(response);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    getRoles() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "role" })
        .then(({ data }) => {
          _this.roles = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    createDisplayName() {
      const _this = this;
      let firstname = "";
      if (_this.updateUser.first_name) {
        firstname = _this.updateUser.first_name.toLowerCase();
      }
      let lastname = "";
      if (_this.updateUser.last_name) {
        lastname = _this.updateUser.last_name.toLowerCase();
      }
      return firstname + lastname;
    },
    async onSubmit() {
      const _this = this;
      try {
        if (!_this.$refs.userForm.validate()) {
          return false;
        }

        _this.formLoading = true;

        if (_this.lodash.isEmpty(_this.updateUser.profile_logo) === false) {
          _this.updateUser.profile_logo = _this.updateUser.profile_logo.id;
        }

        _this.$store
          .dispatch(POST, {
            url: "user",
            data: _this.updateUser,
          })
          .then(() => {
            _this.backForce = true;
            _this.$router.push(
              _this.getDefaultRoute("user", {
                query: {
                  status: "active",
                },
              })
            );
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
  },
  mounted() {
    this.getRoles();
    this.getOptions();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "User", route: "user" },
      { title: "Create" },
    ]);
  },
  computed: {
    getProfileImage() {
      return this.lodash.isEmpty(this.updateUser.profile_logo) === false
        ? this.updateUser.profile_logo.file.url
        : null;
    },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.backForce) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
